export const animLightPop = {
	// scaleX: [1, 1.02, 0.97, 1],
	scaleX: [1, 1.02, 0.99, 1],
	transition: {
		// type: `spring`,
		// stiffness: 1000,
		// damping: 10,
		// // repeat: 0,
		// duration: 10,

		duration: 0.2,
		ease: "easeInOut",
		repeat: 0,
		repeatDelay: 0,
		delay: 0,
	},
};

export default {
	animLightPop,
};
