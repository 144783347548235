import merge from "lodash/merge.js";
import type React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useMeasure } from "react-use/esm/index.js";

import { animLightPop } from "./animations.ts";

// Interface for Props
interface PopAnimationProps {
	children: (props: {
		triggerAnimation: () => void;
		animationProps: any;
		ref: any;
		triggeredVariant: any;
	}) => React.ReactNode;
	getVariant?: (props: { width: number; defaultTriggeredVariant: any }) => any;
	initialState?: any;
	triggeredVariant?: any;
}

// Main Component
export const TriggerAnimation = ({
	children,
	getVariant,
	triggeredVariant = animLightPop,
	initialState = {
		scale: 1,
	},
}: PopAnimationProps) => {
	// State variables
	const [animationState, setAnimationState] = useState("notTriggered");
	const [currentVariant, setCurrentVariant] = useState(triggeredVariant);

	// Measuring DOM element
	const [ref, { width }] = useMeasure();

	// Trigger Animation
	const triggerAnimation = useCallback(() => {
		setAnimationState("triggered");
	}, []);

	// Merge default and dynamic variants
	const mergedTriggeredVariant = merge({}, triggeredVariant, currentVariant);

	// Calculate variant on width change or default variant change
	useEffect(() => {
		const newVariant = getVariant
			? getVariant({ width, defaultTriggeredVariant: triggeredVariant })
			: null;
		setCurrentVariant(newVariant || triggeredVariant);
	}, [getVariant, triggeredVariant, width]);

	// Generate animation variants
	const calculatedVariants = {
		notTriggered: initialState,
		triggered: { ...mergedTriggeredVariant },
	};

	// Generate animation props
	const animationProps = {
		variants: calculatedVariants,
		initial: "notTriggered",
		animate: animationState,
		onAnimationComplete: () => setAnimationState("notTriggered"),
	};

	// Render children with provided props
	return (
		<>
			{children({
				triggerAnimation,
				animationProps,
				ref,
				triggeredVariant,
			})}
		</>
	);
};
